import { render, staticRenderFns } from "./EAEStudentForm.vue?vue&type=template&id=33884630&scoped=true"
import script from "./EAEStudentForm.vue?vue&type=script&lang=js"
export * from "./EAEStudentForm.vue?vue&type=script&lang=js"
import style0 from "./EAEStudentForm.vue?vue&type=style&index=0&id=33884630&prod&scoped=true&lang=scss"
import style1 from "./EAEStudentForm.vue?vue&type=style&index=1&id=33884630&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33884630",
  null
  
)

export default component.exports